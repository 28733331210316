import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/logo.png';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import telegram from '../assets/telegram.png';
import rs from '../assets/rs.png';
import lineLeaves from '../assets/palm_leaves_05-[Converted].png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const tweet = () => {
	window.open("https://twitter.com/frogtokenbsc");
}

const tg = () => {
	window.open("https://t.me/frogtokenbsc");
}

const rSniper = () => {
    window.open("#");
}


//<div class="devs"><a href="https://www.fiverr.com/appslkofficial" target="_blank">Meet the Devs Here</a> <img src={dev}/></div>

class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">

                <div class="footer">
                    <div class="logoF"><img src={logo} /></div>
                    <div class="footer2">
                        <div class="footerImg">
                            <img src={os} />
                            <img onClick={tweet} src={twitter} />
                            <img onClick={tg} src={telegram} />

                        </div>
                        <div class="copyright">Copyright © 2023 FROG CHAIN. All Rights Reserved</div>
                    </div>

                </div>

            </div>
        )
    }
}

export default Footer;

